import { generateContainerRoutes } from '@libero/cityview/modules/container/generate-routes';
import { generateFileRoutes } from '@libero/cityview/modules/file/generate-routes';
import { generateTicketRoutes } from '@libero/cityview/modules/ticket/generate-routes';
import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'dashboard',
    name: 'ticket-dashboard.index',
    component: () => import('./views/pages/Dashboard.vue'),
    children: [
      ...generateContainerRoutes('ticket-dashboard'),
      ...generateTicketRoutes('ticket-dashboard'),
      ...generateFileRoutes('ticket-dashboard'),
    ],
    meta: {
      title: 'Dashboard',
      permission: 'ticket.index',
    },
  },
  {
    path: 'analyses',
    name: 'ticket-analyses.index',
    component: () => import('./views/pages/Analyses.vue'),
    meta: {
      title: 'Inzicht',
      permission: 'ticket.index',
    },
  },
];
