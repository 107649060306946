import type { RouteRecordRaw } from 'vue-router';

import ContractLayout from './views/layouts/ContractLayout/ContractLayout.vue';

const contractChildren = Object.values(
  import.meta.glob(`../(contract-item|price-list|term-sheet|lead-time)/routes.ts`, {
    eager: true,
  }) as Record<string, { routes: RouteRecordRaw[] }>,
)
  .map((routes) => routes.routes)
  .flat();

export const routes: RouteRecordRaw[] = [
  {
    path: 'contract',
    name: 'contract.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      {
        path: 'create',
        name: 'contract.create',
        component: () => import('./views/modals/Create.vue'),
        meta: {
          delay: true,
          permission: 'contract.store',
        },
      },
      {
        path: ':contractId/edit',
        name: 'contract.edit',
        component: () => import('./views/modals/Edit.vue'),
        meta: {
          delay: true,
          permission: 'contract.update',
        },
      },
    ],
    meta: {
      title: 'Systeem instellingen',
      permission: 'contract.index',
    },
  },
  {
    path: 'contract/:contractId',
    component: ContractLayout,
    children: [
      ...contractChildren,
      {
        path: 'log',
        name: 'contract.log',
        component: () => import('../contract/views/pages/Log.vue'),
        meta: {
          title: 'Contract log',
          permission: 'contract.show',
        },
      },
      {
        name: 'contract.show',
        path: '',
        redirect: { name: 'contract-item.index' },
      },
    ],
  },
];
