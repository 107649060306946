<script setup lang="ts">
import {
  ArrowDownTrayIcon,
  BoltIcon,
  ChartBarSquareIcon,
  ChevronDownIcon,
  CubeIcon,
  DocumentTextIcon,
  FolderIcon,
  InboxIcon,
  RectangleGroupIcon,
  ShieldCheckIcon,
  SignalIcon,
  Squares2X2Icon,
  StarIcon,
} from '@heroicons/vue/24/solid';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import Themes from '@libero/organisms/Themes/Themes.vue';
import { Color } from '@libero/types/Color';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import HeightMotion from '@libero/ui-framework/HeightMotion/HeightMotion.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import NavLink from '@libero/ui-framework/NavLink/NavLink.vue';
import Scrollable from '@libero/ui-framework/Scrollable/Scrollable.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  expanded: boolean;
}

defineProps<Props>();

const { t } = useI18n();
const { hasPermission } = useUserStore();

const showThemes = ref(false);

const toggleShowThemes = () => {
  showThemes.value = !showThemes.value;
};

const themesEnabled = import.meta.env.VITE_THEMES_ENABLED === 'true';
const energyEnabled = import.meta.env.VITE_ENERGY_ENABLED === 'true';
</script>

<template>
  <nav class="navigation" role="navigation">
    <Scrollable>
      <template v-if="themesEnabled">
        <Stack :gap="0.25">
          <Tooltip
            class="navigation__tooltip"
            :content="t('theme.themes')"
            placement="right"
            :disabled="expanded"
          >
            <NavLink class="navigation__link" isPartialActive :onClick="toggleShowThemes">
              <Squares2X2Icon class="navigation__icon" />

              <Cluster
                class="navigation__label"
                alignItems="center"
                justifyContent="space-between"
                :gap="0"
              >
                <span>
                  {{ t('theme.themes') }}
                </span>

                <Icon size="lg" :color="Color.gray500" :isRotated="showThemes">
                  <ChevronDownIcon />
                </Icon>
              </Cluster>

              <Icon
                v-if="!expanded"
                :color="Color.gray500"
                :isRotated="showThemes"
                size="sm"
                hasFadeAnimation
              >
                <ChevronDownIcon />
              </Icon>
            </NavLink>
          </Tooltip>

          <HeightMotion>
            <Themes v-if="showThemes" :expanded="expanded" />
          </HeightMotion>
        </Stack>

        <div class="navigation__line" />
      </template>

      <Tooltip
        class="navigation__tooltip"
        :content="t('ticket-data.dashboard')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink class="navigation__link" :to="{ name: 'ticket-dashboard.index' }" isPartialActive>
          <RectangleGroupIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('ticket-data.dashboard') }}</span>
        </NavLink>
      </Tooltip>

      <Tooltip
        class="navigation__tooltip"
        :content="t('ticket-data.analyses')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink class="navigation__link" :to="{ name: 'ticket-analyses.index' }" isPartialActive>
          <ChartBarSquareIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('ticket-data.analyses') }}</span>
        </NavLink>
      </Tooltip>

      <div class="navigation__line" />

      <Tooltip
        class="navigation__tooltip"
        :content="t('container.containers')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink
          class="navigation__link"
          :to="{ name: 'container.index' }"
          :isDisabled="!hasPermission('container.index')"
          isPartialActive
        >
          <CubeIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('container.containers') }}</span>
        </NavLink>
      </Tooltip>

      <Tooltip
        class="navigation__tooltip"
        :content="t('ticket.tickets')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink
          class="navigation__link"
          :to="{ name: 'ticket.index' }"
          :isDisabled="!hasPermission('ticket.index')"
          isPartialActive
        >
          <InboxIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('ticket.tickets') }}</span>
        </NavLink>
      </Tooltip>

      <div class="navigation__line" />

      <Tooltip
        class="navigation__tooltip"
        :content="t('energy.energy')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink
          class="navigation__link"
          :to="energyEnabled ? { name: 'energy.index' } : undefined"
          :isDisabled="!hasPermission('energy.index')"
          :href="energyEnabled ? undefined : '#'"
          isPartialActive
        >
          <BoltIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('energy.energy') }}</span>
        </NavLink>
      </Tooltip>

      <Tooltip
        class="navigation__tooltip"
        :content="t('sensor-registry.sensor-registry')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink
          class="navigation__link"
          :to="{ name: 'sensor-registry.index' }"
          :isDisabled="!hasPermission('sensor-registry.index')"
          isPartialActive
        >
          <SignalIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('sensor-registry.sensor-registry') }}</span>
        </NavLink>
      </Tooltip>

      <Tooltip
        class="navigation__tooltip"
        :content="t('e-safety.e-safety')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink
          class="navigation__link"
          :to="{ name: 'e-safety.index' }"
          :isDisabled="!hasPermission('e-safety.index')"
          isPartialActive
        >
          <ShieldCheckIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('e-safety.e-safety') }}</span>
        </NavLink>
      </Tooltip>

      <div class="navigation__line" />

      <Tooltip
        class="navigation__tooltip"
        :content="t('favorite.favorites')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink class="navigation__link" :to="{ name: 'favorite.index' }" isPartialActive>
          <StarIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('favorite.favorites') }}</span>
        </NavLink>
      </Tooltip>

      <Tooltip
        class="navigation__tooltip"
        :content="t('document.documents')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink
          class="navigation__link"
          :to="{ name: 'document.index' }"
          :isDisabled="!hasPermission('document.index')"
          isPartialActive
        >
          <FolderIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('document.documents') }}</span>
        </NavLink>
      </Tooltip>

      <Tooltip
        class="navigation__tooltip"
        :content="t('contract.contracts')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink
          class="navigation__link"
          :to="{ name: 'contract.index' }"
          :isDisabled="!hasPermission('contract.index')"
          isPartialActive
        >
          <DocumentTextIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('contract.contracts') }}</span>
        </NavLink>
      </Tooltip>

      <Tooltip
        class="navigation__tooltip"
        :content="t('export.exports')"
        placement="right"
        :disabled="expanded"
      >
        <NavLink
          class="navigation__link"
          :to="{ name: 'export.index' }"
          :isDisabled="!hasPermission('export.index')"
          isPartialActive
        >
          <ArrowDownTrayIcon class="navigation__icon" />
          <span class="navigation__label">{{ t('export.exports') }}</span>
        </NavLink>
      </Tooltip>
    </Scrollable>
  </nav>
</template>

<style lang="scss" scoped>
@import '@libero/cityview/modules/app/views/segments/Navigation/Navigation.scss';
</style>
