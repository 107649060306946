import { RouteRecordRaw } from 'vue-router';

import DataSettingsLayout from './views/layouts/DataSettingsLayout/DataSettingsLayout.vue';

const children = Object.values(
  import.meta.glob(`../(attribute-type|entity-type|product)/routes.ts`, {
    eager: true,
  }) as Record<string, { routes: RouteRecordRaw[] }>,
)
  .map((routes) => routes.routes)
  .flat();

export const routes: RouteRecordRaw[] = [
  {
    path: '/data-settings',
    component: DataSettingsLayout,
    children: [
      ...children,
      {
        path: '',
        redirect: { name: 'entity-type.index' },
      },
    ],
  },
];
